<template>
    <b-modal id="modalDriverCar" title="Добавить автомобиль" size="lg" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="fuelTypes()">
        <b-row class="d-flex justify-content-center flex-column">
            <b-col cols="12">
                <b-row>
                    <b-col cols="4" style="border-right: 1px solid #ebe9f1;">
                        <label for="">Подразделение: <i class="required">*</i></label>
                        <v-select v-model="driverCars.division_id" :reduce="option => option.id" placeholder="Подразделение"
                            label="name" :options="divisions" class="select-size-md" />
                    </b-col>
                    <b-col cols="4">
                        <label for="">Поиск автомобиля:</label>
                        <b-input placeholder="Номер автомобиля" v-model="car_number.car_number" v-mask="'####AA##'" />
                    </b-col>
                    <b-col cols="4" style="margin-top: 24px;">
                        <b-button variant="primary" @click="searchCar()">Найти автомобиль</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" class="p-1 m-1 border-color">
                <div class="d-flex justify-content-between">
                    <h4><strong>Технические характеристики:</strong></h4>
                    <h5 style="margin-right: 10px;"><strong>Для грузовых автомобилей:</strong></h5>
                </div>
                <b-row>
                    <b-col cols="4" class="pr-1 border-right">
                        <label for="">Категория <i class="required">*</i></label>
                        <v-select v-model="driverCars.category_car_id" :reduce="car_categorie => car_categorie.id"
                            placeholder="Категория" @input="getCarData()" label="name" :options="car_categories"
                            class="select-size-md">
                            <template #no-options>
                                <span>
                                    Данные отсутствуют!
                                </span>
                            </template>
                        </v-select>
                        <label for="">Марка <i class="required">*</i></label>
                        <v-select v-model="car_brand_id" placeholder="Марка"
                            :disabled="condition" label="name" :options="car_brands" :reduce="option => option.id" class="select-size-md"
                            @input="searchModel()">
                            <template #no-options>
                                <span>
                                    Данные отсутствуют!
                                </span>
                            </template>
                        </v-select>
                        <label for="">Модель<i class="required">*</i></label>
                        <v-select v-model="driverCars.model_car_id" placeholder="Модель" :reduce="option => option.id"
                            :disabled="condition2" label="car_model" :options="car_models" class="select-size-md"
                            @input="setSelected()">
                            <template #no-options>
                                <span>
                                    Данные отсутствуют!
                                </span>
                            </template>
                        </v-select>
                        <label for="">Тип кузова</label>
                        <v-select :disabled="condition" v-model="driverCars.body_type_id"
                            :reduce="body_type => body_type.id" placeholder="Тип кузова" label="name"
                            :options="body_types" class="select-size-md">
                            <template #no-options>
                                <span>
                                    Данные отсутствуют!
                                </span>
                            </template>
                        </v-select>
                        <label for="">Тип топлива</label>
                        <v-select
                            :reduce="options => options.id"
                            v-model="driverCars.fuel_type_id"
                            placeholder="Тип топлива"
                            label="name"
                            :options="fuel_types"
                            class="select-size-md"
                        >
                            <template #no-options>
                              <span>
                                Данные отсутствуют!
                              </span>
                            </template>
                        </v-select>
                        <label for="">Год выпуска <i class="required">*</i></label>
                        <b-input v-model="driverCars.year_of_issue" placeholder="2022" v-mask="'####'" />
                    </b-col>
                    <b-col cols="4" class="pl-1 border-right">
                        <label for="">Цвет <i class="required">*</i></label>
                        <v-select @input="colorSelected()" v-model="driverCars.color_id" :reduce="car_color => car_color.id" placeholder="Цвет"
                            label="name" :options="car_colors" class="select-size-md">
                            <template #no-options>
                                <span>
                                    Данные отсутствуют
                                </span>
                            </template>
                        </v-select>
                        <label for="">Состояние <i class="required">*</i></label>
                        <v-select v-model="driverCars.condition_id" :reduce="car_condition => car_condition.id"
                            placeholder="Состояние" label="name" :options="car_conditions" class="select-size-md" />
                        <label for="">Колличество мест <i class="required">*</i></label>
                        <b-input v-model="driverCars.count_seat" placeholder="4" :style="driverCars.count_seat > max_count_seat && max_count_seat != '' ? 'border: 1px solid red;' : ''"/>
                            <template v-if="driverCars.count_seat > max_count_seat && max_count_seat != ''">
                                <small class="required" style="font-size: 10px;">Колличество пассажирских мест данной марки не может превышать {{max_count_seat}}</small> <br>
                            </template>
                        <label for="">Гос. номер <i class="required">*</i></label>
                        <b-input v-model="driverCars.car_number" placeholder="1111AA01" v-mask="'####AA##'"
                            @input="checkNumber()" />
                    </b-col>
                    <b-col>
                        <label for="">Грузоподъемность (т) <i class="required">*</i></label>
                        <b-input :disabled="freight_car" v-model="cargo_properties.carrying_capacity" placeholder="" />
                        <label for="">Длина (м)</label>
                        <b-input :disabled="freight_car" v-model="cargo_properties.length" placeholder="" />
                        <label for="">Ширина (м)</label>
                        <b-input :disabled="freight_car" v-model="cargo_properties.width" placeholder="" />
                        <label for="">Высота (м)</label>
                        <b-input :disabled="freight_car" v-model="cargo_properties.height" placeholder="" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" class="p-1 m-1 border-color">
                <h4><strong> Дополнительные опции: </strong></h4>
                <div class="d-flex">
                    <b-form-group class="m-0" style="column-count: 3; width: 100%;">
                        <b-form-checkbox v-for="option in dop_options" :key="option.id" v-model="selected"
                            :value="option.id" name="flavour-3a" style="margin-top: 2px;">
                            {{ option.name }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col class="d-flex justify-content-end">
                <b-button @click="connect_car ? connectCar() :  addCar()" variant="primary">{{button_text}}
                </b-button>
                <b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
            </b-col>
        </b-row>
        <b-modal id="infoModal" centered :no-close-on-backdrop="true" ok-only>
            <div>
                {{info}}
            </div>
        </b-modal>
    </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mask } from "vue-the-mask"
export default {
    components: {
        vSelect,
        ToastificationContent
    },
    directives: { mask },
    props: [],
    data() {
        return {
            fuel_types: [],
            car_brands: [],
            button_text: 'Добавить',
            condition2: true,
            driverCars: {
                division_id: '',
                model_car_id: '',
                category_car_id: '',
                color_id: '',
                condition_id: '',
                body_type_id: '',
                year_of_issue: '',
                car_number: '',
                count_seat: '',
                cargo_properties: '',
                dop_options: [],
                dop_info: undefined,
                fuel_type_id: null,
            },
            car_id: '',
            car_number: {
                car_number: ''
            },
            check_number: {
                car_number: ''
            },
            car_categories: [],
            car_models: [],
            dop_options: [],
            cargo_properties: {
                carrying_capacity: '',
                length: '',
                width: '',
                height: ''
            },
            body_types: [],
            car_brand_id: null,
            car_colors: [],
            car_conditions: [],
            condition: true,
            freight_car: true,
            divisions: [],
            selected: [],
            info: '',
            max_count_seat: '',
            connect_car: false,
            car_name: undefined,
            car_color: undefined,
            car_info: undefined
        }
    },
    mounted() {
        this.getDopOptions()
        this.$http
            .get('directory/cars')
            .then(res => {
                this.car_categories = res.data.car_categories
                this.car_colors = res.data.car_colors
                this.car_conditions = res.data.car_conditions
            })
        this.$http
            .get('directory/divisions')
            .then(res => {
                this.divisions = res.data
                this.driverCars.division_id = 1
            })
        this.$http
            .get('body-types')
            .then(res => {
                this.body_types = res.data
            })
        let userData = JSON.parse(localStorage.getItem('userData'))
        this.driverCars.division_id = userData.division_id
    },
    methods: {
        getDopOptions() {
            if(this.driverCars.category_car_id) {
                this.$http
                .get(`cars/dop-options?category_car_id=${this.driverCars.category_car_id}`)
                .then(res => {
                    this.dop_options = res.data
                })
            }
        },
        addCar() {
            this.driverCars.dop_info = this.driverCars.dop_info ? this.driverCars.dop_info : undefined
            this.driverCars.body_type_id = this.driverCars.body_type_id ? this.driverCars.body_type_id : undefined
            this.submitButtonDisabled = true
            if (this.driverCars.category_car_id == 3) {
                this.driverCars.cargo_properties = JSON.stringify(this.cargo_properties)
            }else{
                this.driverCars.cargo_properties = undefined
            }
            if (this.selected.length > 0) {
                this.driverCars.dop_options = []
                let options = []
                this.selected.forEach(el => {
                    options.push({car_option_id: el})
                });
                this.driverCars.dop_options = JSON.stringify(options)
            }else {
                this.driverCars.dop_options = undefined
            }
            this.$http
                .post('cars', this.driverCars)
                .then(res => {
                    this.car_id = res.data.car_id
                    this.connectCar()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Машина успешно добавлена!`,
                        },
                    })
                    this.sendCarInfo()
                    this.$bvModal.hide('modalDriverCar')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        sendCarInfo(){
            if (this.driverCars.car_number) {
                this.car_info = this.car_name + '(' + this.driverCars.car_number + ')' + ' ' + this.car_color
            }else {
                this.car_info = ''
            }
            this.$emit("sendCarInfo", this.car_info)
        },
        connectCar(){
            this.sendCarInfo()
            this.$bvModal.hide('modalDriverCar')
            this.$emit("connectCar", this.car_id)
        },
        fuelTypes() {
            this.$http.get('cars/fuel-types')
                .then((res) => {
                    this.fuel_types = res.data
                })
        },
        closeModal() {
            if (this.car_id) {
                this.$bvModal.hide('modalDriverCar')
            }else {
                this.$bvModal.hide('modalDriverCar')
                this.driverCars = {
                    division_id: '',
                    model_car_id: '',
                    category_car_id: 2,
                    color_id: '',
                    condition_id: '',
                    body_type_id: '',
                    year_of_issue: '',
                    car_number: '',
                    count_seat: '',
                    cargo_properties: '',
                    dop_options: [],
                    dop_info: undefined
                }
                this.condition = true
                this.car_number = {
                    car_number: ''
                }
                this.cargo_properties = {
                    carrying_capacity: '',
                    length: '',
                    width: '',
                    height: ''
                }
                this.car_id = ''
                this.selected = []
                this.connect_car = false
                this.car_brand_id = undefined
                this.button_text = 'Добавить'
                this.sendCarInfo()

            }
        },
        getCarData() {
            if (this.driverCars.category_car_id != null) {
                let car_categorie = {
                    category_car_id: this.driverCars.category_car_id
                }
                this.getDopOptions()
                this.$http
                    .post('cars/model-cars', car_categorie)
                    .then(res => {
                        this.car_brands = res.data.car_brands
                        this.body_types = res.data.body_types
                        this.condition = false
                    })
            } else {
                this.condition = true
                this.driverCars.car_model_id = ''
                this.driverCars.body_type_id = ''
            }
            if (this.driverCars.category_car_id == 3) {
                this.freight_car = false
            } else {
                this.freight_car = true
            }
        },
        searchModel() {
            if (this.car_brand_id) {
                this.driverCars.model_car_id = undefined
                let car_brand = {
                    category_car_id: this.driverCars.category_car_id,
                    car_brand_id: this.car_brand_id
                }
                this.$http
                    .post('cars/model-cars', car_brand)
                    .then(res => {
                        this.car_models = res.data.model_cars
                        this.body_types = res.data.body_types
                        this.condition2 = false
                    })
            } else {
                this.condition2 = true
                this.driverCars.car_model_id = ''
                this.driverCars.body_type_id = ''
            }
            if (this.driverCars.category_car_id == 3) {
                this.freight_car = false
            } else {
                this.freight_car = true
            }
        },
        searchCar() {
            this.$http
                .post('cars/search-car', this.car_number)
                .then(res => {
                    let newCar = res.data
                    this.car_id = newCar.id
                    this.car_brand_id = newCar.car_brand_id
                    this.driverCars = {
                        division_id: newCar.division_id,
                        model_car_id: newCar.model_car_id,
                        category_car_id: newCar.category_car_id,
                        color_id: newCar.color_id,
                        condition_id: newCar.condition_id,
                        body_type_id: newCar.body_type_id,
                        year_of_issue: newCar.year_of_issue,
                        car_number: newCar.car_number,
                        count_seat: newCar.count_seat,
                        cargo_properties: '',
                        dop_options: [],
                        dop_info: undefined
                    }
                    if (newCar.cargo_properties != null) {
                        this.cargo_properties = newCar.cargo_properties
                    }
                    if (newCar.dop_options != null) {
                        newCar.dop_options.forEach(el => {
                            this.selected.push(el.car_option_id)
                        })
                    }
                    this.getCarData()
                    this.searchModel()
                    this.colorSelected()
                    this.car_name = newCar.car_brand + ' ' + newCar.model
                    this.connect_car = true
                    this.button_text = 'Прикрепить'
                })
                .catch(err => {
                    if (err.response.status == 404) {
                        this.info = 'По данному номеру не найдено ни одного автомобиля'
                        this.$bvModal.show('infoModal')
                        this.connect_car = false
                        this.button_text = 'Добавить'
                    }
                })
        },
        setSelected() {
            this.car_brands.forEach(el => {
                if (el.id == this.car_brand_id) {
                    this.car_name = el.name
                }
            })
            this.car_models.forEach(el => {
                if (el.id == this.driverCars.model_car_id) {
                    this.driverCars.count_seat = el.car_seat_before
                    this.driverCars.condition_id = 2
                    this.max_count_seat = el.car_seat_before
                    this.car_name = this.car_name + ' ' +  el.car_model
                }
            })
        },
        checkNumber() {
            if (this.driverCars.car_number.length == 8) {
                this.check_number = {
                    car_number: this.driverCars.car_number
                }
                this.$http
                    .post('cars/search-car', this.check_number)
                    .then(res => {
                        if (res.data) {
                            this.info = `В базе уже есть автомобиль с таким гос. номером.(Подразделение: ${res.data.division}, Статус: ${res.data.active == 0 ? 'Не работает' : 'Работает'}, ID: ${res.data.id})`
                            this.$bvModal.show('infoModal')
                        }
                    })
                    .catch(err => {
                        
                    })
            }
        },
        colorSelected(){
            this.car_colors.forEach(el =>{
                if (el.id == this.driverCars.color_id) {
                    this.car_color = el.name
                }
            })
        }
    }
}
</script>

<style scoped>
.border-color {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: auto;
}
.required {
    color: red;
}
</style>