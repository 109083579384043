<template>
    <div>
        <b-modal
        id="driversCreateModal"
        title="Добавить исполнителя"
        size="lg"
        hide-footer no-enforce-focus
        :no-close-on-backdrop="true"
        @show="Dates()"
        @shown="detectStartTime"
        @hidden="detectEndTime"
        >
            <b-row class="d-flex justify-content-center flex-column">
                <b-col cols="12" class="p-1 m-1 border-color">
                    <h5><strong>Личная информация</strong></h5>
                    <b-row>
                        <b-col cols="4" class="pr-1 border-right">
                            <label for="">Подразделение <i class="required">*</i></label>
                            <v-select 
                                v-model="drivers.division_id" 
                                :reduce="option => option.id"
                                placeholder="Подразделение"
                                label="name" 
                                :options="divisions" 
                                class="select-size-md" />
                            <label for="">Фамилия <i class="required">*</i></label>
                            <b-input v-model="drivers.last_name" placeholder="Фамилия" />
                            <label for="">Имя <i class="required">*</i></label>
                            <b-input v-model="drivers.first_name" placeholder="Имя" />
                        </b-col>
                        <b-col cols="4" class="pl-1 border-right">
                            <label for="">Отчество</label>
                            <b-input v-model="drivers.patronymic" placeholder="Отчество" />
                            <label for="example-input">Дата рождения <i class="required">*</i></label>
                            <b-input-group class="">
                                <b-form-input 
                                    id="example-input"
                                    v-model="drivers.date_of_birth" 
                                    type="date"
                                    autocomplete="off"
                                    show-decade-nav 
                                    :max="currDate" />
                            </b-input-group>
                            <p class="mt-1 required" v-if="drivers.date_of_birth > currDate" style="font-size: 11px;">
                                Ошибка! Водитель не может быть младше 18 лет!
                            </p>
                            <label for="" class="mb-1">Пол <i class="required">*</i></label>
                            <div class="d-flex justify-content-between adaptiv">
                                <b-form-radio 
                                    name="some-radios" 
                                    v-for="gender in genders" 
                                    :key="gender.id"
                                    :value="gender.id" 
                                    v-model="drivers.gender">
                                        {{ gender.name }}
                                </b-form-radio>
                            </div>
                        </b-col>
                        <b-col>
                            <h5 class="mb-3" @click="getDriverData"><strong>Контакная информация</strong></h5>
                            <label for="">Телефон <i class="required">*</i></label>
                            <b-input v-model="drivers.phone" placeholder="992927777777" />
                            <label for="">Дополнительный номер</label>
                            <b-input v-model="drivers.contact_number" placeholder="992927777777" />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" class="border-color p-1 m-1">
                    <div class="d-flex justify-content-between">
                        <h5><strong>Удостоверение личности</strong></h5>
                        <h5 style="margin-right: 65px;" class="car"><strong>Водительское удостоверение</strong></h5>
                    </div>
                    <b-row>
                        <b-col cols="8" class="pr-1 border-right">
                            <label for="">Номер удостоверения личности <i v-if="checked" class="required">*</i></label>
                            <b-input v-model="drivers.serial_number_passport" :style="checked && !drivers.serial_number_passport  ? 'border-color: red' : ''" type="text" placeholder="(1 буква 8 цифр)"
                                v-mask="'A########'" />
                            <p v-if="checked && !drivers.serial_number_passport" class=" mb-0 required" style="font-size: 11px">При регистрации водителя без автомобиля, это поле обязательно для заполнения</p>
                            <b-row>
                                <b-col cols="6" class="pr-1 border-right mt-1">
                                    <label for="example-input">Действителен до</label>
                                    <b-input-group class="">
                                        <b-form-input 
                                            id="example-input" 
                                            v-model="drivers.expirated_passport"
                                            type="date" 
                                            :min="DateValidator" 
                                            autocomplete="off" 
                                            show-decade-nav />
                                    </b-input-group>
                                    <label for="">Адрес</label>
                                    <b-input v-model="drivers.address" class="form-control" placeholder="Адрес" />
                                </b-col>
                                <b-col cols="6" class="pl-1 mt-1">
                                    <label for="">Орган, выдающий документ</label>
                                    <v-select 
                                        v-model="drivers.passport_office_id"
                                        :reduce="option => option.id"
                                        placeholder="Орган, выдающий документ" 
                                        label="name" 
                                        :options="passport_offices"
                                        class="select-size-md" />
                                    <label for="" class="">Район проживания</label>
                                    <v-select 
                                        v-model="drivers.district_id"
                                        :reduce="option => option.id" 
                                        placeholder="Район"
                                        label="name" 
                                        :options="districts" 
                                        class="select-size-md" />
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="4">
                            <label for="">Номер вод.удостоверения</label>
                            <b-input v-model="drivers.serials_number" type="text" placeholder="(2 буквы 7 цифр)"
                                v-mask="'AA#######'" />
                            <label for="example-input" style="margin-top: 16px">Действителен до</label>
                            <b-input-group class="">
                                <b-form-input 
                                    id="example-input" 
                                    v-model="drivers.expirated_driver_license" 
                                    type="date"
                                    :min="DateValidator" 
                                    autocomplete="off" 
                                    show-decade-nav 
                                    />
                            </b-input-group>
                            <b-button :disabled="checked" v-b-modal.modalDriverCar
                                style="margin-top: 24px; width: 100%;" variant="outline-primary">
                                <feather-icon icon="PlusIcon" />
                                Добавить автомобиль
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-input disabled class="mt-2" v-model="carInfo"></b-input>
                    <label for="" class="mt-2">Дополнительная информация</label>
                    <b-form-textarea  v-model="drivers.dop_info" placeholder="Введите текст"></b-form-textarea>
                </b-col>
            </b-row>
            <div :style="`width: 200px; ${carId && carId.length >0 ? 'display: none' : 'display: block'}`">
                <label for="1" class="check"><input id="1" type="checkbox" v-model="checked">
                    <div class="without">Без автомобиля</div>
                </label>
            </div>
            <b-row class="mb-1">
                <b-col class="d-flex justify-content-end">
                    <b-button :disabled="submitButtonDisabled || (checked && !drivers.serial_number_passport) || (!checked && !carId)" @click="driverCreate()" variant="primary">Добавить
                    </b-button>
                    <b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import { mask } from "vue-the-mask"

export default {
    directives: { mask },
    components: {
        vSelect,
        ToastificationContent,
    },
    props: ["carId", "driver", "car_info"],
    data() {
        return {
            formTime: {
                form_name: null,
                start_time: null,
                end_time: null,
            },
            drivers: {
                division_id: 1,
                first_name: '',
                last_name: '',
                patronymic: undefined,
                phone: '',
                contact_number: undefined,
                date_of_birth: '',
                serials_number: undefined,
                expirated_driver_license: undefined,
                gender: '',
                serial_number_passport: undefined,
                expirated_passport: undefined,
                district_id: '',
                passport_office_id: undefined,
                address: '',
                transport: undefined,
                car_id: undefined,
                dop_info: undefined
            },
            passport_offices: [],
            districts: [],
            divisions: [],
            carInfo: undefined,
            passport_offices: [],
            formatted: '',
            selected: '',
            currDate: undefined,
            DateValidator: undefined,
            checked: false,
            submitButtonDisabled: false,
            genders: [
                { id: 0, name: 'Женский' },
                { id: 1, name: 'Мужской' }
            ]
        }
    },
    mounted() {
        this.$http
            .get('drivers/data')
            .then(res => {
                this.passport_offices = res.data.passport_offices
                this.divisions = res.data.divisions
                this.passport_offices = res.data.passport_offices
            })
        this.$http
            .get('addresses/districts').then(res => {
                this.districts = res.data
            })
    },
    methods: {
        detectEndTime() {
            this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.$http.post('time-form', this.formTime)
            .then((res) => {
            })
            .catch((err) => {
            }) 
        },
        detectStartTime() {
            this.formTime.form_name = 'driverCreateForm'
            this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        },
        getDriverData() {
            this.drivers.phone = this.driver.phone
            this.drivers.first_name = this.driver.first_name
            this.drivers.last_name = this.driver.last_name
            this.drivers.patronymic = this.driver.patronymic
            this.drivers.division_id = this.driver.division_id
        },
        driverCreate() {
            this.drivers.dop_info = this.drivers.dop_info ? this.drivers.dop_info : undefined
            this.drivers.patronymic = this.drivers.patronymic ? this.drivers.patronymic : undefined
            this.drivers.district_id = this.drivers.district_id ? this.district_id : undefined
            this.drivers.passport_office_id = this.drivers.passport_office_id ? this.passport_office_id : undefined
            this.drivers.address = this.drivers.address ? this.drivers.address : undefined
            if (!this.checked) {
                this.drivers.serial_number_passport = this.serial_number_passport ? this.drivers.serial_number_passport : undefined
            }else{
                this.drivers.car_id = undefined
            }
            this.drivers.contact_number ? this.drivers.contact_number : this.drivers.contact_number = undefined
            if (this.drivers.serials_number == null || this.drivers.serials_number == "") {
                this.drivers.serials_number = undefined
            }
            if (this.drivers.expirated_driver_license == null || this.drivers.expirated_driver_license == "") {
                this.drivers.expirated_driver_license = undefined
            }
            if (this.drivers.expirated_passport == null || this.drivers.expirated_passport == "") {
                this.drivers.expirated_passport = undefined
            }
            if (this.carId) {
                this.drivers.car_id = this.carId
            }
            this.submitButtonDisabled = true
            this.$http
                .post('drivers', this.drivers)
                .then(res => {
                    this.$bvModal.hide('driversCreateModal')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Водитель успешно добавлен!`,
                        },
                    })
                    this.$emit('refresh')
                    this.$emit('clearCarId', undefined)
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
        },
        onContext(ctx) {
            this.selected = ctx.selectedYMD
            this.formatted = ctx.selectedFormatted
        },
        closeModal() {
            this.drivers = {
                division_id: '',
                first_name: '',
                last_name: '',
                patronymic: undefined,
                phone: '',
                contact_number: undefined,
                date_of_birth: '',
                serials_number: undefined,
                expirated_driver_license: undefined,
                gender: '',
                serial_number_passport: undefined,
                expirated_passport: undefined,
                district_id: '',
                passport_office_id: undefined,
                address: '',
                dop_info: undefined
            },
            this.divisions_select = undefined
            this.car_select = undefined
            this.district_select = undefined
            this.passport_select = undefined
            this.checked = false
            this.carInfo = undefined
            this.$bvModal.hide('driversCreateModal')
        },
        Dates() {
            this.car_info = undefined
            let curr = new Date()
            let max = new Date(curr.getTime() - 567993600000)
            let a = new Date(max).toISOString().split('T')[0]
            let b = new Date().toISOString().split('T')[0]
            this.currDate = a
            this.DateValidator = b
        },
    },
    watch: {
        driver(driver) {
            if(driver){
                this.getDriverData()
            }
        },
        car_info(car_info){
            if (car_info) {
                this.carInfo = car_info
            }else {
                this.carInfo = ''
            }
        },
        driver: {
            handler(newValue){
                if (newValue.phone) {
                    this.getDriverData()
                }
            },
            deep: true
        }
    },
}
</script>

<style scoped>
.border-color {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: auto;
}

.borders {
    border: 1px solid #D9D9D9;
}

.without {
    font-size: 18px;
    padding-left: 5px;
}

.check {
    display: flex;
}

.required{
    color: red;
}

@media (max-width: 993px) {
    .adaptiv {
        display: block !important;
    }

    .car {
        margin-right: 0 !important;
    }
}
</style>